import React, { useState, useEffect } from 'react';
import Select from '../../common/Select';
import { put } from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

export default function InstanceType({ list, instanceId, value, status, disabled }) {
  const [showInstanceTypesList, setShowInstanceTypesList] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (value) {
      const type = list.find((it) => it.value === value);
      if (type) {
        setSelected(type);
      }
    }
  }, [value]);

  useEffect(() => {
    if (status !== 'stopped') {
      setShowInstanceTypesList(false);
    }
  }, [status]);

  const _onEdit = () => {
    if (status === 'stopped' && showInstanceTypesList === false && !disabled) {
      setShowInstanceTypesList(!showInstanceTypesList);
    }
  };

  const _onChange = async (type) => {
    try {
      const prevType = selected;
      setLoading(true);
      setShowInstanceTypesList(false);
      setSelected(type);
      const response = await put(`/instance/${instanceId}`, { instanceType: type.value });
      if (response.status !== 200) {
        setSelected(prevType);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={status === 'stopped' && !showInstanceTypesList && !disabled ? 'instance-type stopped' : 'instance-type'} onClick={_onEdit}>
      {!showInstanceTypesList && selected && selected.label}
      {status === 'stopped' && showInstanceTypesList && !disabled && (
        <div className='types-list'>
          <Select
            list={list}
            listKey='value'
            listLabel='label'
            onChange={_onChange}
            selected={selected && selected.value}
            currentOption={selected && selected.label}
            containerStyle={{ width: 200 }}
            small
          />
        </div>
      )}
      {disabled && <FontAwesomeIcon icon={faLock} color='#43586f' style={{ fontSize: 12, marginLeft: 8 }} />}
    </div>
  );
}
