import React from 'react';
import { connect } from 'react-redux';

import { addParticipantRequest, moveParticipantRequest } from '../../redux/breakoutRoomsRedux';

import Room from './Room';

const RoomsGrid = (props) => {
  const { rooms, roomSize, maxRooms, moveParticipant, addParticipant, config, roomsData, startTime } = props;
  const _maxRooms = startTime ? maxRooms : maxRooms + 2;
  const roomsPlaceholders = [];
  const roomsArray = [];

  const _addParticipant = (data) => {
    const { participantId, roomId, oldRoomId, removeSubStatus } = data;
    addParticipant(roomId, participantId, config._id, oldRoomId, removeSubStatus);
  };

  const _moveParticipant = (dt) => {
    const { participantId, oldRoomId, newRoomId, removeSubStatus } = dt;
    const { activeEvent } = props;
    moveParticipant(oldRoomId, newRoomId, participantId, config._id, removeSubStatus, activeEvent._id);
  };

  const emptySpaces = _maxRooms - rooms.length;
  for (let i = 1; i <= rooms.length; i += 1) {
    const roomId = rooms[i - 1];
    const room = roomsData[roomId];

    roomsArray.push(
      <Room
        key={`room_${room.index}`}
        roomId={roomId}
        index={room.index}
        capacity={roomSize}
        onAddParticipant={_addParticipant}
        onMoveParticipant={_moveParticipant}
        startTime={startTime}
        maxRooms={maxRooms}
      />,
    );
  }

  for (let i = 0; i < emptySpaces; i += 1) {
    roomsPlaceholders.push(<div key={`placeholder_${i}`} className='room-placeholder' />);
  }

  return (
    <div className='rooms-grid-wrapper'>
      <div className='rooms-grid'>
        {roomsArray}
        {roomsPlaceholders}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeEvent: state.events.active,
  config: state.breakoutRooms.config,
  roomsData: state.breakoutRooms.rooms,
});

const mapDispatchToProps = (dispatch) => ({
  addParticipant: (roomId, participantId, configId, old, removeSubStatus) =>
    dispatch(addParticipantRequest(roomId, participantId, configId, old, removeSubStatus)),
  moveParticipant: (from, to, participantId, configId, removeSubStatus, eventId) =>
    dispatch(moveParticipantRequest(from, to, participantId, configId, removeSubStatus, eventId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomsGrid);
