export const socketServerInstanceTypes = [
  { value: 'c6a.2xlarge', label: '500 Off Wall - c6a.2xlarge', maxOffWallParticipants: 500 },
  { value: 'c6a.4xlarge', label: '1000 Off Wall - c6a.4xlarge', maxOffWallParticipants: 1000 },
  { value: 'c6a.8xlarge', label: '2500 Off Wall - c6a.8xlarge', maxOffWallParticipants: 2500 },
  { value: 'c6a.16xlarge', label: '10000 Off Wall - c6a.16xlarge', maxOffWallParticipants: 10000 },
];

export const compositorInstanceTypes = [
  { value: 'g4dn.12xlarge', label: 'g4dn.12xlarge', pricePerHour: 3.9 },
  { value: 'g4dn.metal',    label: 'g4dn.metal', pricePerHour: 7.8 },
  { value: 'g5.12xlarge',   label: 'g5.12xlarge', pricePerHour: 5.6 },
  { value: 'g5.24xlarge',   label: 'g5.24xlarge', pricePerHour: 8.1 },
  { value: 'g5.48xlarge',   label: 'g5.48xlarge', pricePerHour: 16.2 },
  { value: 'g6.12xlarge',   label: 'g6.12xlarge', pricePerHour: 4.6 },
  { value: 'g6.24xlarge',   label: 'g6.24xlarge', pricePerHour: 6.6 },
  { value: 'g6.48xlarge',   label: 'g6.48xlarge', pricePerHour: 13.3 },
  { value: 'g6e.12xlarge',  label: 'g6e.12xlarge', pricePerHour: 10.4 },
  { value: 'g6e.24xlarge',  label: 'g6e.24xlarge', pricePerHour: 15.0 },
  // { value: 'g6e.48xlarge',  label: 'g6e.48xlarge', pricePerHour: 30.1 },
].sort((a, b) => a.pricePerHour - b.pricePerHour);

export const decoderServerInstanceTypes = [
  { value: 'c5.9xlarge', label: '30 On Wall - c5.9xlarge', maxOnWallParticipants: 30 },
  { value: 'c5.18xlarge', label: '75 On Wall - c5.18xlarge', maxOnWallParticipants: 75 },
  { value: 'c5.24xlarge', label: '100 On Wall - c5.24xlarge', maxOnWallParticipants: 100 },
  { value: 'c6i.32xlarge', label: '150 On Wall - c6i.32xlarge', maxOnWallParticipants: 150 },
  { value: 'c6a.32xlarge', label: '150 On Wall - c6a.32xlarge', maxOnWallParticipants: 150 },
  { value: 'c6a.48xlarge', label: '200 On Wall - c6a.48xlarge', maxOnWallParticipants: 200 },
];

export const rtcServerInstanceTypes = [
  { value: 'c5.9xlarge', label: '15 On Wall - c5.9xlarge', maxOnWallParticipants: 15 },
  { value: 'c5.12xlarge', label: '20 On Wall - c5.12xlarge', maxOnWallParticipants: 20 },
  { value: 'c5.18xlarge', label: '30 On Wall - c5.18xlarge', maxOnWallParticipants: 30 },
  { value: 'c5.24xlarge', label: '40 On Wall - c5.24xlarge', maxOnWallParticipants: 40 },
  { value: 'c6a.32xlarge', label: '60 On Wall - c6a.32xlarge', maxOnWallParticipants: 60 },
];
