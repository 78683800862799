import React, { useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faPauseCircle, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

import ContextMenu from '../../common/ContextMenu';
import { post, put } from '../../services/api';
import SocketClient from '../../utils/socket-client';
import wallIcon from '../../assets/images/navbar/wall-green.svg';
import { OFF_WALL_ROLES, ON_WALL_ROLES, VENUE_ROLES, VENUE_OFF_WALL, VENUE_ON_WALL, VENUE_PARTICIPANT } from '../../utils/user-roles';

const EventParticipant = ({
  item,
  onSelect,
  selected,
  adminAppUser,
  selectedParticipants,
  onContextMenuAction,
  appMode,
  includeOffWall,
  isAutomatedOnboardingEnabled,
}) => {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

  const { firstName, lastName, phone, notes } = item.participant;
  const { status, inScreeningQueueSince, role, helpRequested, isWallReady, paused, browserMinimized } = item;

  const _onContextMenuHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowContextMenu(true);
    setContextMenuPosition({ x: e.pageX, y: e.pageY });
  };

  const _hideContextMenu = () => {
    setShowContextMenu(false);
    setContextMenuPosition({ x: 0, y: 0 });
  };

  const _getContextMenuItems = () => {
    if (appMode === 'BROADCAST') {
      return [
        {
          value: 2,
          label: 'Refresh',
        },
      ];
    }

    if (selectedParticipants.length <= 1 && (status === 'ARCHIVED' || status === 'REMOVED' || role === VENUE_PARTICIPANT)) {
      return [];
    }

    let roleOptions = [
      { value: 13, label: 'Wall Participant' },
      { value: 12, label: 'Wall Presenter' },
      { value: 15, label: 'Off-wall Participant' },
      { value: 14, label: 'Off-wall Admin' },
      { value: 16, label: 'Observer' },
      { value: 11, label: 'Event Support' },
    ];

    let statusOptions = [];

    if (includeOffWall || VENUE_ROLES.includes(role)) {
      statusOptions = [
        { value: 'ARCHIVED', label: 'Archived' },
        { value: 'REMOVED', label: 'Removed' },
      ];
    } else {
      statusOptions = [
        { value: 'IN_SCREENING_QUEUE', label: 'In Screening Queue' },
        { value: 'IN_STUDIO_QUEUE', label: 'In Studio Queue' },
        { value: 'ARCHIVED', label: 'Archived' },
        { value: 'REMOVED', label: 'Removed' },
      ];
    }

    if (role === VENUE_OFF_WALL) {
      roleOptions = [{ value: 17, label: 'Venue On-wall' }];
    } else if (role === VENUE_ON_WALL) {
      roleOptions = [{ value: 18, label: 'Venue Off-wall' }];
      statusOptions = [
        { value: 'IN_STUDIO_QUEUE', label: 'In Studio Queue' },
        { value: 'ARCHIVED', label: 'Archived' },
        { value: 'REMOVED', label: 'Removed' },
      ];
    }

    if (isAutomatedOnboardingEnabled) {
      statusOptions.unshift({ value: 'ONBOARDING', label: 'Onboarding' });
    }

    if (selectedParticipants.length <= 1) {
      roleOptions = roleOptions.filter((r) => r.label !== role);
      statusOptions = statusOptions.filter((s) => s.label !== status);
    }

    let items = [];
    if (selectedParticipants.length <= 1 && status === 'NOT_CONNECTED' && ON_WALL_ROLES.includes(role)) {
      items = [
        {
          value: 1,
          label: 'Change Role',
          options: roleOptions,
        },
      ];
    } else {
      items = [
        {
          value: 1,
          label: 'Change Role',
          options: roleOptions,
        },
        {
          value: 3,
          label: 'Change Status',
          options: statusOptions,
        },
        {
          value: 2,
          label: 'Refresh',
        },
      ];
    }
    return items;
  };

  const _onContextMenuItemSelection = async (option, subOption) => {
    switch (option.value) {
      case 1:
        const newRole = subOption.label;

        if (selectedParticipants.length > 1) {
          for (const p of selectedParticipants) {
            if (p.role !== newRole) {
              _changeRole(p.role, newRole, p);
            }
          }
        } else {
          _changeRole(item.role, newRole, item);
        }

        onContextMenuAction();
        break;

      case 2:
        if (selectedParticipants.length > 1) {
          for (const p of selectedParticipants) {
            _refreshBrowser(p);
          }
        } else {
          _refreshBrowser(item);
        }

        onContextMenuAction();
        break;

      case 3:
        const newStatus = subOption.value;

        if (selectedParticipants.length > 1) {
          for (const p of selectedParticipants) {
            if (p.status !== newStatus) {
              _changeStatus(newStatus, p);
            }
          }
        } else {
          _changeStatus(newStatus, item);
        }

        onContextMenuAction();
        break;
      default:
        break;
    }
  };

  const _refreshBrowser = (ep) => {
    SocketClient.emit('refresh-browser', { uuid: ep.participant._id });
  };

  const _changeRole = async (oldRole, newRole, ep) => {
    try {
      if (OFF_WALL_ROLES.includes(oldRole) && ON_WALL_ROLES.includes(newRole)) {
        await post('/eventParticipant/roleChange', {
          event: ep.event,
          participant: ep.participant._id,
          fromRole: oldRole,
          toRole: newRole,
          adminAppUser: adminAppUser._id,
        });
        SocketClient.emit('change-off-wall-to-on-wall', { role: newRole, uuid: ep.participant._id });
      } else {
        await post('/eventParticipant/roleChange', {
          event: ep.event,
          participant: ep.participant._id,
          fromRole: oldRole,
          toRole: newRole,
          adminAppUser: adminAppUser._id,
        });
        await put(`/eventParticipant/${ep._id}`, { role: newRole, adminAppUser: adminAppUser._id });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const _changeStatus = async (newStatus, ep) => {
    try {
      let data = { status: newStatus };
      if (newStatus === 'ONBOARDING') {
        data = { ...data, systemCheck: null, screeningCompleted: false, helpRequested: false, paused: false };
      }
      await put(`/eventParticipant/${ep._id}`, data);
    } catch (err) {
      console.error(err);
    }
  };

  const _onClickHandler = (event) => {
    event.persist();
    onSelect && onSelect(event, item);
  };

  let statusClass;
  if (status === 'NOT_CONNECTED') {
    statusClass = 'status offline';
  } else if (status === 'IN_STUDIO_QUEUE' || status === 'ON_WALL' || status === 'ON_AIR') {
    statusClass = 'status online';
  } else if (status === 'IN_SCREENING_QUEUE' || status === 'IN_SCREENING' || status === 'ONBOARDING') {
    statusClass = item.hasWebRTCConnection ? 'status queue' : 'status connecting';
  }

  if (appMode === 'BROADCAST' && status !== 'NOT_CONNECTED') {
    statusClass = item.hasEnteredMeeting ? 'status online' : 'status connecting';
  }

  const name = `${item.wallDisplayNum ? `${item.wallDisplayNum}. ` : ''}${firstName} ${lastName || '\u00a0'}`;
  const isMultipleSelected = selectedParticipants.length > 0 && selectedParticipants.findIndex((p) => p._id === item._id) > -1;
  const listItemClass = selected || isMultipleSelected ? 'participant-list-item selected' : 'participant-list-item';

  return (
    <div className={listItemClass} onClick={_onClickHandler} onContextMenu={_onContextMenuHandler}>
      {showContextMenu && (
        <ContextMenu position={contextMenuPosition} items={_getContextMenuItems()} onClose={_hideContextMenu} onItemSelected={_onContextMenuItemSelection} />
      )}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', width: '100%' }}>
          <div className={statusClass} />
          <div className='name'>
            {name}
            <div style={{ fontSize: 11, color: 'gray' }}>{role}</div>
          </div>
          <div style={{ position: 'absolute', right: 10, display: 'flex', flexDirection: 'row' }}>
            {helpRequested && <FontAwesomeIcon icon={faExclamationCircle} color='#b71330' style={{ fontSize: 17, marginLeft: 5 }} />}
            {browserMinimized && <FontAwesomeIcon icon={faWindowRestore} color='#b71330' style={{ fontSize: 15, position: 'relative', top: 1 }} />}
            {paused && <FontAwesomeIcon icon={faPauseCircle} color='#b7771e' style={{ fontSize: 17, marginLeft: 5 }} />}
            {isWallReady && <img src={wallIcon} width={20} height={20} style={{ position: 'relative', marginLeft: 5, top: -2 }} />}
          </div>
          {status === 'ON_AIR' && <span style={{ marginLeft: 5, color: '#b71330', fontWeight: 600 }}>ON AIR</span>}
          {status === 'REMOVED' && <span style={{ marginLeft: 5, color: '#b71330', fontWeight: 600 }}>REMOVED</span>}
          {status === 'ARCHIVED' && <span style={{ marginLeft: 5, color: '#b7771e', fontWeight: 600 }}>ARCHIVED</span>}
        </div>
        <div style={{ marginTop: 5, marginLeft: status === 'ARCHIVED' ? 0 : 25 }}>{phone}</div>
      </div>

      {notes && notes !== '' && (
        <div
          style={{
            display: 'flex',
            margin: '8px 0px 4px 24px',
            padding: 4,
            color: '#888888',
            border: '1px solid #4e6379',
            textAlign: 'left',
          }}
        >
          {notes}
        </div>
      )}

      {inScreeningQueueSince && inScreeningQueueSince !== null && (
        <div
          style={{
            textAlign: 'right',
            color: '#888',
            fontSize: 11,
            marginTop: 4,
          }}
        >
          {moment(inScreeningQueueSince).fromNow()}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  adminAppUser: state.auth.user,
  appMode: state.ui.appMode,
});

export default connect(mapStateToProps, null)(EventParticipant);
