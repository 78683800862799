import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import editIcon from '../../assets/icons/edit.svg';
import TextInput from '../../common/TextInput';

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
  },
  editButton: {
    marginLeft: 15,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
  },
  icon: {
    color: '#a9aaab',
  },
};

function WallMetadataFormFields({ data, onChange }) {
  const activeEvent = useSelector((state) => state.events.active);

  const [formFields, setFormFields] = useState([]);
  const [formData, setFormData] = useState({});
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    if (activeEvent) {
      setFormFields(activeEvent.erpFormFields);
    }
  }, [activeEvent]);

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  if (formFields.length === 0) {
    return null;
  }

  return (
    <>
      <div style={styles.header}>
        <h4>Registration Info</h4>
        <button
          type='button'
          className='edit-icon'
          style={styles.editButton}
          onClick={() => {
            setCanEdit(!canEdit);
          }}
        >
          <img src={editIcon} alt='edit' style={{ width: 20 }} />
        </button>
      </div>
      <div>
        {formFields.map((field) => (
          <TextInput
            name={field.metadataKey}
            label={field.label}
            onChange={(event) => {
              setFormData({ ...formData, [field.metadataKey]: event.target.value });
              onChange({ ...formData, [field.metadataKey]: event.target.value });
            }}
            value={formData[field.metadataKey]}
            disabled={!canEdit}
          />
        ))}
      </div>
    </>
  );
}

WallMetadataFormFields.propTypes = {
  data: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
};

WallMetadataFormFields.defaultProps = {
  data: {},
};

export default WallMetadataFormFields;
