import React, { useState, useEffect } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, ArcElement, Tooltip, Legend, PointElement, LineElement, Filler } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { post, setStudioIdHeader, setToken, setErpIntegrationHeader } from '../../services/api';
import PandoLogo from '../../assets/images/pando-logo.png';
import BroadcastLogo from '../../assets/images/inception-logo-blue.png';
import Spinner from '../../common/Spinner';
import Button from '../../common/Button';
import { engagementChartColors } from '../../utils/charts-util';

import HtmlBarChart from './HtmlBarChart';
import DoughnutChart from './DoughnutChart';
import PieChart from './PieChart';
import LineChart from './LineChart';
import { Table, TableHeader, TableBody, TableRow } from './TableElements';
import PollingDetails from './PollingDetails';
import LoginForm from './LoginForm';
import '../../css/Reports.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, ChartDataLabels, Tooltip, Legend, PointElement, LineElement, Filler);
ChartJS.defaults.color = '#0f0f0f';

const { REACT_APP_API_BASE_URL } = process.env;

function Reports() {
  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [attendanceData, setAttendanceData] = useState(null);
  const [interactivityData, setInteractivityData] = useState(null);
  const [pieScoreData, setPieScoreData] = useState(null);
  const [connectionData, setConnectionData] = useState(null);
  const [engagementCountersData, setEngagementCountersData] = useState(null);
  const [engagementDatasets, setEngagementDatasets] = useState(null);
  const [title, setTitle] = useState('');
  const [dowloadingReport, setDownloadingReport] = useState(false);

  const _getReportData = async (event, email, accessCode) => {
    const { data: reportData } = await post(`/eventReport`, { eventId: event._id, email, accessCode });
    setAttendanceData(reportData.attendanceData);
    setInteractivityData(reportData.interactivityData);
    setPieScoreData(reportData.pieScoreData);
    setConnectionData(reportData.connectionData);

    const isPando = event.eventType === 'PANDO';

    const reportingEngagementTypes = isPando
      ? ['TOTAL', 'THUMBS_UP', 'THUMBS_DOWN', 'RAISE_HAND', 'CHAT_MESSAGE', 'SUBMIT_ANSWER_OF_POLL']
      : ['TOTAL', 'CHAT_MESSAGE', 'SUBMIT_ANSWER_OF_POLL', 'QUESTION_SUBMITTED'];
    const reportingEngagementLabels = isPando
      ? ['Total', 'Thumbs Up', 'Thumbs Down', 'Raised Hands', 'Chat Message', 'Poll Answered']
      : ['Total', 'Chat Message', 'Poll Answered', 'Q&A'];
    Object.entries(reportData.engagementCounters).forEach(([key, value]) => {
      reportingEngagementTypes.forEach((type) => {
        if (!value[type]) {
          value[type] = 0;
        }
      });
    });
    const datasets = [];
    reportingEngagementTypes.forEach((type, i) => {
      datasets.push({
        label: reportingEngagementLabels[i],
        data: Object.values(reportData.engagementCounters).map((value) => value[type]),
      });
    });
    setEngagementCountersData(reportData.engagementCounters);
    setEngagementDatasets(datasets);
  };

  useEffect(() => {
    // override the background color of the admin app and make it is white for the report page
    document.body.style.backgroundColor = '#fff';

    async function fetchEventData() {
      try {
        const { search } = window.location;
        const decodedParams = window.atob(search.substring(1, search.length));
        const urlParams = new URLSearchParams(decodedParams);

        const studioId = urlParams.get('studioId');
        const eventId = urlParams.get('eventId');
        const token = urlParams.get('token');
        const isAdminAppUser = urlParams.get('isAdminAppUser');
        
        setStudioIdHeader(studioId);

        const { data: eventData } = await post(`/event/eventForId`, { eventId });
        setEventData(eventData);
        const title = eventData.eventType === 'PANDO' ? 'Pando Meeting Report' : 'Broadcast Meeting Report';
        setTitle(title);
        document.title = title;

        const canRequestReportData = !!eventData.startTime && !!eventData.endTime;

        const email = sessionStorage.getItem('email');
        const accessCode = sessionStorage.getItem('accessCode');
        if ((email && accessCode) || isAdminAppUser || token) {
          let isAuthorized = false;
          if (isAdminAppUser) {
            isAuthorized = localStorage.getItem('token') ? true : false;
          } else if (token) {
            setToken(token);
            setErpIntegrationHeader();
            isAuthorized = true;
          } else {
            const { data } = await post(`/auth/access/validate`, { email, accessCode, eventId });
            isAuthorized = data.isValid;
          }
          setIsAuthorized(isAuthorized);
          setLoading(false);
          if (!isAuthorized) {
            sessionStorage.removeItem('email');
            sessionStorage.removeItem('accessCode');
          } else if (canRequestReportData) {
            await _getReportData(eventData, email, accessCode);
          } else {
            swal({
              title: 'Missing Start/End Time',
              text: 'This event does not have a start or end time set. Please set the start and end time to view the report.',
            });
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchEventData();
  }, []);

  const _downloadReport = () => {
    const filename = `${title} - ${eventData.name}.xlsx`;
    const email = sessionStorage.getItem('email');
    const accessCode = sessionStorage.getItem('accessCode');
    setDownloadingReport(true);
    axios({
      url: `${REACT_APP_API_BASE_URL}/report/downloadReport`,
      method: 'POST',
      responseType: 'blob', // important
      data: {
        filename,
        eventId: eventData._id,
        email,
        accessCode,
      },
    })
      .then((res) => {
        setDownloadingReport(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
        setDownloadingReport(false);
      });
  };

  const _downloadArchiveReport = () => {
    const filename = `Broadcast Archive Report - ${eventData.name}.xlsx`;
    setDownloadingReport(true);
    axios({
      url: `${REACT_APP_API_BASE_URL}/report/downloadArchiveReport`,
      method: 'POST',
      responseType: 'blob',
      data: {
        filename,
        eventId: eventData._id,
      },
    }).then((res) => {
      setDownloadingReport(false);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }).catch((error) => {
      console.log(error);
      setDownloadingReport(false);
    });
  };

  const _renderInteractivityTable = () => {
    let tableHeaders = ['Total Polls', 'Total Responses', 'Unique Responses', 'Response Rate'];
    let tableData = [
      interactivityData.pollingDetails.length,
      interactivityData.polling.totalPollResponses,
      interactivityData.polling.uniqueParticipants,
      interactivityData.polling.pollResponseRate,
    ];

    if (eventData.enableChat) {
      tableHeaders.push('Total Chat Messages');
      tableData.push(interactivityData.summary.CHAT_MESSAGE);
    }
    if (eventData.enableQNAWidget) {
      tableHeaders.push('Total Q&A');
      tableData.push(interactivityData.summary.QUESTION_SUBMITTED);
    }

    return (
      <Table>
        <TableHeader>{tableHeaders}</TableHeader>
        <TableBody>
          <TableRow>{tableData}</TableRow>
        </TableBody>
      </Table>
    );
  };

  const _renderSpinner = () => {
    return (
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <Spinner mode='dark' />
      </div>
    );
  };

  if (loading) {
    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>{_renderSpinner()}</div>;
  }

  if (!isAuthorized && eventData) {
    return (
      <LoginForm
        eventId={eventData._id}
        onSubmit={async ({ isAuthorized, email, accessCode }) => {
          setIsAuthorized(isAuthorized);
          if (isAuthorized) {
            sessionStorage.setItem('email', email);
            sessionStorage.setItem('accessCode', accessCode);
            try {
              await _getReportData(eventData, email, accessCode);
            } catch (error) {
              console.log(error);
            }
          } else {
            swal({
              title: 'Error',
              text: 'Invalid access code',
            });
          }
        }}
      />
    );
  }

  const isPando = eventData && eventData.eventType === 'PANDO';

  return (
    <div className='reports-container'>
      {eventData && (
        <div className='reports-header'>
          <img src={isPando ? PandoLogo : BroadcastLogo} alt='Logo' style={{ width: 140, margin: '17px 30px 0 15px' }} />
          <div style={{ marginTop: 15 }}>
            <h2>{title}</h2>
            <h3 style={{ margin: '4px 0' }}>{eventData.name}</h3>
            <h4>
              <span style={{ marginRight: 15 }}>{`${moment(eventData.startTime).format('MM/DD/YYYY')}`} </span>
              {`${moment(eventData.startTime).format('hh:mm A')} - ${moment(eventData.endTime).format('hh:mm A')}`}
            </h4>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', marginRight: 30 }}>
            {!isPando ? (
              <Button
                text={
                  dowloadingReport ? (
                    <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 24 }}>
                      <Spinner style={{ transform: 'scale(0.4)' }} />
                      <span>Downloading...</span>
                    </span>
                  ) : (
                    'Archive Details'
                  )
                }
                onClick={_downloadArchiveReport}
                containerStyle={{ transform: 'scale(0.85)' }}
                disabled={dowloadingReport}
              />
            ) : null}
            <Button
              text={
                dowloadingReport ? (
                  <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 24 }}>
                    <Spinner style={{ transform: 'scale(0.4)' }} />
                    <span>Downloading...</span>
                  </span>
                ) : (
                  isPando ? 'Download Details' : 'Live Details'
                )
              }
              onClick={_downloadReport}
              containerStyle={{ transform: 'scale(0.85)' }}
              disabled={dowloadingReport}
            />
          </div>
        </div>
      )}
      <section className='reports-section'>
        <h3 style={{ marginTop: 60 }}>Attendance Overview</h3>
        <div className='section-description'>
          {isPando ? (
            <p style={{ width: 700 }}>
              The Attendance Overview provides a high-level summary of the attendance record for your program. It includes number of registrants vs actual
              attendees for all participant types, as well as the average amount of time your participants spent logged into the program.
            </p>
          ) : (
            <p style={{ width: 700 }}>
              The Attendance Overview provides a high-level summary of the attendance record for your program. It includes number of registrants vs actual
              attendees, as well as the average amount of time your participants spent logged into the program.
            </p>
          )}
        </div>

        {attendanceData ? (
          <>
            <Table>
              <TableHeader>{isPando ? ['', 'Wall Participants', 'Off Wall Participants', 'Observers', 'Total'] : ['', 'Total']}</TableHeader>
              <TableBody>
                <TableRow>
                  {isPando
                    ? [
                        '# of registrations',
                        attendanceData.registrants.onWall,
                        attendanceData.registrants.offWall,
                        attendanceData.registrants.observer,
                        attendanceData.registrants.total,
                      ]
                    : ['# of registrations', attendanceData.registrants.total]}
                </TableRow>
                <TableRow>
                  {isPando
                    ? [
                        '# of attendees',
                        attendanceData.attendees.onWall,
                        attendanceData.attendees.offWall,
                        attendanceData.attendees.observer,
                        attendanceData.attendees.total,
                      ]
                    : ['# of attendees', attendanceData.attendees.total]}
                </TableRow>
                <TableRow>
                  {isPando
                    ? [
                        'Avg. attendance time (min)',
                        attendanceData.attendees.onWallAvgDuration,
                        attendanceData.attendees.offWallAvgDuration,
                        attendanceData.attendees.observerAvgDuration,
                        '',
                      ]
                    : ['Avg. attendance time (min)', attendanceData.attendees.offWallAvgDuration]}
                </TableRow>
              </TableBody>
            </Table>
            <div className='chart-area'>
              <DoughnutChart
                title='Total'
                data={[attendanceData.attendees.total, attendanceData.registrants.total - attendanceData.attendees.total]}
                labels={['Attended', 'Did not attend']}
                style={{ width: 240 }}
              />
              {isPando && attendanceData.registrants.onWall > 0 && (
                <DoughnutChart
                  title='On Wall'
                  data={[attendanceData.attendees.onWall, attendanceData.registrants.onWall - attendanceData.attendees.onWall]}
                  labels={['Attended', 'Did not attend']}
                  style={{ width: 240 }}
                />
              )}
              {isPando && attendanceData.registrants.offWall > 0 && (
                <DoughnutChart
                  title='Off Wall'
                  data={[attendanceData.attendees.offWall, attendanceData.registrants.offWall - attendanceData.attendees.offWall]}
                  labels={['Attended', 'Did not attend']}
                  style={{ width: 240 }}
                />
              )}
            </div>
          </>
        ) : (
          _renderSpinner()
        )}
      </section>
      <section className='reports-section'>
        <h3 style={{ marginTop: 100 }}>Active Connections</h3>
        <div className='section-description'>
          <p style={{ width: 700 }}>
            The Active Connection section provides an overview of the timing of when your meeting participants were logged in and logged out of the program. The
            graph below spans the duration of your program and shows when peak attendance occurred.
          </p>
        </div>
        {connectionData ? (
          <div className='chart-area' style={{ marginTop: 30 }}>
            <LineChart
              labels={Object.keys(connectionData).map((k) => {
                const time = new Date(parseInt(k));
                return `${time.getHours() < 10 ? '0' + time.getHours() : time.getHours()}:${
                  time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
                }`;
              })}
              data={Object.values(connectionData)}
              style={{ width: 700 }}
              displayLegend={false}
            />
          </div>
        ) : (
          _renderSpinner()
        )}
        <h3 style={{ marginTop: 100 }}>Engagement Timeline</h3>
        <div className='section-description'>
          {isPando ? (
            <p style={{ width: 700 }}>
              The Engagement Timeline provides insight into when your meeting participants were actively engaged with your program. You can see when
              participants raised their hands, sent chat messages, gave a thumbs up/down, or answered a poll. You can get even more detailed information by
              clicking on any of the engagement activities at the bottom of the chart to zoom in on participant engagement for individual activities (thumbs up,
              chat messages, etc.).
            </p>
          ) : (
            <p style={{ width: 700 }}>
              The Engagement Timeline provides insight into when your meeting participants were actively engaged with your program. You can see when
              participants sent chat messages or answered a poll. You can get even more detailed information by clicking on any of the engagement activities at
              the bottom of the chart to zoom in on participant engagement for individual activities.
            </p>
          )}
        </div>
        {engagementCountersData ? (
          <div className='chart-area' style={{ marginTop: 30 }}>
            <LineChart
              labels={Object.keys(engagementCountersData).map((k) => {
                const time = new Date(parseInt(k));
                return `${time.getHours() < 10 ? '0' + time.getHours() : time.getHours()}:${
                  time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
                }`;
              })}
              datasets={engagementDatasets}
              colors={engagementChartColors}
              style={{ width: 700 }}
              displayLegend={true}
            />
          </div>
        ) : (
          _renderSpinner()
        )}
      </section>
      {/* {isPando && (
        <section className='reports-section' style={{ marginTop: 60 }}>
          <h3>PIE Score</h3>
          <div className='section-description'>
            <p style={{ width: 700 }}>
              The Pando Index of Engagement is an internal mechanism used to identify the most engaged participants both on and off-wall, and provide them with
              a PIE score. This is accomplished by measuring the most highly engaged participants against those that were not as engaged based on their level of
              interactivity during the program.
            </p>
          </div>
          {pieScoreData ? (
            <>
              <Table>
                <TableHeader>{['Max', 'Min', 'Average']}</TableHeader>
                <TableBody>
                  <TableRow>{[pieScoreData.highestPieScore, pieScoreData.lowestPieScore, pieScoreData.averagePieScore]}</TableRow>
                </TableBody>
              </Table>
              {pieScoreData.onWallParticipants.length > 0 ? (
                <div className='chart-area'>
                  <HtmlBarChart
                    title='Wall Participants'
                    data={pieScoreData.onWallParticipants}
                    style={{ width: 725, maxHeight: 500, overflowY: 'auto', overflowX: 'hidden', justifyContent: 'flex-start' }}
                  />
                </div>
              ) : null}
              {pieScoreData.offWallParticipants.length > 0 ? (
                <div className='chart-area'>
                  <HtmlBarChart
                    title='Off Wall Participants'
                    data={pieScoreData.offWallParticipants}
                    style={{ width: 725, maxHeight: 500, overflowY: 'auto', overflowX: 'hidden', justifyContent: 'flex-start' }}
                  />
                </div>
              ) : null}
            </>
          ) : (
            _renderSpinner()
          )}
        </section>
      )} */}
      <section className='reports-section page-break' style={{ marginTop: 60 }}>
        <h3>Interactivity</h3>
        <div className='section-description'>
          {isPando ? (
            <p style={{ width: 700 }}>
              The Interactivity section provides graphical and numerical representation of participant engagement with the activities available during the Pando
              program. Thumbs up/down, raised hands, polling, and chat messages each have their own pie chart to show you engagement for each activity.{' '}
              <p>
                Please note: Only on-wall participants have access to the "raise hand" feature, therefore the numbers represented in the charts below may not
                reflect the total number of participants in the meeting.
              </p>
            </p>
          ) : (
            <p style={{ width: 700 }}>
              The Interactivity section provides graphical and numerical representation of participant engagement with the activities available during the
              program. Polling, and chat messages each have their own pie chart to show you engagement for each activity.{' '}
            </p>
          )}
        </div>
        {interactivityData ? (
          <>
            {isPando && (
              <>
                <Table>
                  <TableHeader>{['Total Thumbs Up', 'Total Thumbs Down', 'Total Raised Hands (On-Wall Only)', 'Total Reactions']}</TableHeader>
                  <TableBody>
                    <TableRow>
                      {[
                        interactivityData.summary.THUMBS_UP,
                        interactivityData.summary.THUMBS_DOWN,
                        interactivityData.summary.RAISE_HAND,
                        interactivityData.summary.THUMBS_UP + interactivityData.summary.THUMBS_DOWN + interactivityData.summary.RAISE_HAND,
                      ]}
                    </TableRow>
                  </TableBody>
                </Table>
                <div className='section-description'>
                  <p style={{ width: 700 }}>
                    The table and charts below represent the number of unique participants that engaged with the interactivity options during the meeting.
                  </p>
                </div>
                <Table>
                  <TableHeader>{['Unique Thumbs Up', 'Unique Thumbs Down', 'Unique Raised Hands']}</TableHeader>
                  <TableBody>
                    <TableRow>{[interactivityData.thumbsUp.total, interactivityData.thumbsDown.total, interactivityData.raisedHands.total]}</TableRow>
                  </TableBody>
                </Table>
                <div className='chart-area' style={{ marginBottom: 60 }}>
                  <PieChart
                    title='Unique Thumbs Up'
                    data={[interactivityData.thumbsUp.total, interactivityData.thumbsUp.none]}
                    labels={['Thumbs Up', 'None']}
                    style={{ width: 240 }}
                  />
                  <PieChart
                    title='Unique Thumbs Down'
                    data={[interactivityData.thumbsDown.total, interactivityData.thumbsDown.none]}
                    labels={['Thumbs Down', 'None']}
                    style={{ width: 240 }}
                  />
                  <PieChart
                    title='Unique Raised Hands (On-Wall Only)'
                    data={[interactivityData.raisedHands.total, interactivityData.raisedHands.none]}
                    labels={['Raised Hands', 'None']}
                    style={{ width: 240 }}
                  />
                </div>
              </>
            )}
            <div className='section-description'>
              <p style={{ width: 700 }}>
                The table and charts below represent both the total and unique number of participants that engaged with polling and chat interactivity options
                during the meeting.
              </p>
            </div>
            {_renderInteractivityTable()}
            <div className='chart-area'>
              {interactivityData && interactivityData.pollingDetails && interactivityData.pollingDetails.length ? (
                <>
                  <PieChart
                    title='Total Poll Responses'
                    data={[interactivityData.polling.totalPollResponses, interactivityData.polling.noResponse]}
                    labels={['Responded', 'No Response']}
                    style={{ width: 240 }}
                  />
                  <PieChart
                    title='Unique Poll Responses'
                    data={[interactivityData.polling.uniqueParticipants, interactivityData.polling.noResponseParticipants]}
                    labels={['Responded', 'No Response']}
                    style={{ width: 240 }}
                  />
                </>
              ) : null}
              {eventData.enableChat && (
                <PieChart
                  title='Unique Chat Messages'
                  data={[interactivityData.chatMessages.sent, interactivityData.chatMessages.none]}
                  labels={['Sent Message', 'None']}
                  style={{ width: 240 }}
                />
              )}
              {eventData.enableQNAWidget && (
                <PieChart title='Unique Q&A' data={[interactivityData.qna.total, interactivityData.qna.none]} labels={['Q&A', 'None']} style={{ width: 240 }} />
              )}
            </div>
          </>
        ) : (
          _renderSpinner()
        )}
      </section>
      {interactivityData && interactivityData.pollingDetails && interactivityData.pollingDetails.length && (
        <section className='reports-section' style={{ marginTop: 100 }}>
          <h3>Polling Details</h3>
          <div className='section-description'>
            <p style={{ width: 700 }}>
              The Polling Details section provides aggregated data for each poll presented to all meeting participants throughout the program. You can view the
              number of responses/non-responses in the graph to the left, as well as the number of responses given to individual response options in the polling
              summary to the right.
            </p>
          </div>
          <PollingDetails data={interactivityData.pollingDetails} />
        </section>
      )}
    </div>
  );
}

export default Reports;
