import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import swal from 'sweetalert';

import { getAwsInstancesRequest, getAwsImagesRequest } from '../../redux/goLiveRedux';
import Button from '../../common/Button';
import TextInput from '../../common/TextInput';
import Input from '../../common/Input';
import Select from '../../common/Select';
import Checkbox from '../../common/Checkbox';

class InstanceForm extends PureComponent {
  static propTypes = {
    edit: PropTypes.bool,
    model: PropTypes.shape({}),
    studio: PropTypes.shape({}),
    awsImages: PropTypes.arrayOf(PropTypes.shape({})),
    awsInstances: PropTypes.arrayOf(PropTypes.shape({})),
    getAwsInstances: PropTypes.func.isRequired,
    getAwsImages: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    edit: false,
    model: null,
    studio: null,
    awsImages: [],
    awsInstances: [],
  };

  state = {
    automaticInstanceTypeSelection: false,
    name: '',
    instanceId: null,
    selectedInstanceIdLabel: null,
    serverType: null,
    subDomain: '',
    selectedImage: null,
    lockInstanceType: false,
  };

  _serverTypes = [
    { value: 'SOCKET_SERVER', label: 'Socket Server' },
    { value: 'RTC', label: 'RTC' },
    { value: 'COMPOSITOR', label: 'Compositor' },
    { value: 'DECODER', label: 'Decoder' },
  ];

  componentDidMount() {
    const { model, studio, getAwsInstances, getAwsImages } = this.props;
    if (model) {
      const { automaticInstanceTypeSelection, name, instanceId, serverType, subDomain, lockInstanceType, region } = model;
      const selectedServerType = this._serverTypes.find((s) => s.value === serverType);
      if (region) {
        getAwsInstances(region);
        getAwsImages(region);
      }
      this.setState({ automaticInstanceTypeSelection, name, instanceId, subDomain, serverType: selectedServerType, lockInstanceType });
    } else if (studio.awsRegion) {
      getAwsInstances(studio.awsRegion);
      getAwsImages(studio.awsRegion);
    }
  }

  componentDidUpdate(prevProps) {
    const { awsImages, awsInstances, model } = this.props;

    if (prevProps.awsImages !== awsImages && awsImages && awsImages.length && model) {
      this._setSelectedImage(model.imageId);
    }

    if (prevProps.awsInstances !== awsInstances && awsInstances && awsInstances.length && model) {
      this._setSelectedInstanceIdLabel(model.instanceId);
    }
  }

  _setSelectedImage = (imageId) => {
    const { awsImages } = this.props;
    const selectedImage = awsImages.find((i) => i.value === imageId);
    if (selectedImage) {
      this.setState({ selectedImage });
    }
  };

  _setSelectedInstanceIdLabel = (instanceId) => {
    const { awsInstances } = this.props;
    const selectedInstance = awsInstances.find((i) => i.instanceId === instanceId);
    if (selectedInstance) {
      this.setState({ selectedInstanceIdLabel: selectedInstance.formSelectLabel });
    }
  };

  _onChangeInstanceId = ({ instanceId, formSelectLabel }) => {
    this.setState({ instanceId, selectedInstanceIdLabel: formSelectLabel });
  };

  _onChangeImage = (data) => {
    this.setState({ selectedImage: data });
  };

  _onChangeServerType = (selectedType) => {
    const isCompositor = selectedType.value === "COMPOSITOR";
    this.setState({ serverType: selectedType, automaticInstanceTypeSelection: isCompositor, lockInstanceType: isCompositor || this.state.lockInstanceType });
  };

  _onChangeText = (event) => {
    const { target } = event;
    this.setState({ [target.name]: target.value });
  };

  _onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const { onSubmit } = this.props;
    const { automaticInstanceTypeSelection, name, instanceId, serverType, subDomain, selectedImage, lockInstanceType } = this.state;
    if (name.length > 0 && instanceId != null && serverType != null) {
      onSubmit({
        automaticInstanceTypeSelection,
        name,
        instanceId,
        subDomain,
        imageId: selectedImage ? selectedImage.value : null,
        serverType: serverType.value,
        lockInstanceType,
      });
    } else {
      swal({
        text: 'Name, instanceId and server type are required',
      });
    }
  };

  render() {
    const subDomainFormControlStyle = {
      display: 'flex',
      color: '#ffffff',
      fontSize: 14,
      justifyContent: 'flex-start',
      alignItems: 'center',
    };
    const subDomainInputStyle = { width: '130px', margin: 0, textAlign: 'center' };

    const { edit, model, awsImages, awsInstances } = this.props;
    const { automaticInstanceTypeSelection, name, instanceId, serverType, subDomain, selectedImage, selectedInstanceIdLabel, lockInstanceType } = this.state;

    return (
      <div className='modal-form' style={{ minWidth: 550 }}>
        <div className='modal-form-header'>
          <h3>{edit ? 'Edit Server' : 'Add Server'}</h3>
        </div>
        <div className='modal-form-content'>
          <form autoComplete='off' onSubmit={this._onSubmit}>
            <TextInput name='name' label='Name' onChange={this._onChangeText} value={name} />
            <div className='form-group'>
              <div className='form-label'>Instance Id</div>
              <div className='form-control'>
                <Select
                  list={awsInstances}
                  listKey='instanceId'
                  listLabel='formSelectLabel'
                  onChange={this._onChangeInstanceId}
                  selected={instanceId}
                  currentOption={selectedInstanceIdLabel}
                  small
                />
              </div>
            </div>
            <div className='form-group'>
              <div className='form-label'>AWS Image Id</div>
              <div className='form-control'>
                <Select
                  list={awsImages}
                  listKey='value'
                  listLabel='label'
                  onChange={this._onChangeImage}
                  selected={selectedImage && selectedImage.value}
                  currentOption={selectedImage && selectedImage.label}
                  listContainerStyle={{ maxHeight: 200 }}
                  small
                />
              </div>
            </div>
            <div className='form-group'>
              <div className='form-label'>Server Type</div>
              <div className='form-control'>
                <Select
                  list={this._serverTypes}
                  listKey='value'
                  listLabel='label'
                  onChange={this._onChangeServerType}
                  selected={serverType && serverType.value}
                  currentOption={serverType && serverType.label}
                  small
                />
              </div>
            </div>
            {serverType && serverType.value === 'SOCKET_SERVER' && (
              <div className='form-group'>
                <div className='form-label'>Sub-domain</div>
                <div className='form-control' style={subDomainFormControlStyle}>
                  <span>https://</span>
                  <Input
                    type='text'
                    name='subDomain'
                    value={subDomain}
                    onChange={this._onChangeText}
                    style={subDomainInputStyle}
                    disabled={!model}
                    inputStyle={{ margin: 0, width: '90%' }}
                    containerStyle={{ margin: '10px 0' }}
                  />
                  <span>.letspando.com</span>
                </div>
              </div>
            )}
            {serverType && serverType.value === 'COMPOSITOR' && (
              <div className='form-group' style={{ marginTop: 20 }}>
                <div className='form-label'>&nbsp;</div>
                <div className='form-control'>
                  <Checkbox
                    label='Automatic Instance Type Selection'
                    value={automaticInstanceTypeSelection}
                    onChange={(value) => this.setState({ automaticInstanceTypeSelection: value, lockInstanceType: value ? true : lockInstanceType })}
                  />
                </div>
              </div>
            )}
            <div className='form-group' style={{ marginTop: 20 }}>
              <div className='form-label'>&nbsp;</div>
              <div className='form-control'>
                <Checkbox
                  label='Lock Instance Type'
                  value={lockInstanceType}
                  onChange={(value) => this.setState({ lockInstanceType: value })}
                  disabled={automaticInstanceTypeSelection}
                />
              </div>
            </div>
          </form>
        </div>
        <div className='modal-form-footer'>
          <Button type='primary' text='Save' onClick={this._onSubmit} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  awsInstances: state.goLive.awsInstances,
  awsImages: state.goLive.awsImages,
});

const mapDispatchToProps = (dispatch) => ({
  getAwsInstances: (regionId) => dispatch(getAwsInstancesRequest(regionId)),
  getAwsImages: (regionId) => dispatch(getAwsImagesRequest(regionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstanceForm);
