import React, { useState } from 'react';
import { connect } from 'react-redux';

import Draggable from '../../common/Draggable';
import ContextMenu from '../../common/ContextMenu';

const RoomParticipant = (props) => {
  const { participant, blockDrag, enableContextMenu, contextMenuItemSelectionHandler, isHost, roomId, contextMenuItems, status, subStatus, roomIndex, oldRoom, maxRooms } =
    props;

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const onContextMenuHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (enableContextMenu) {
      setContextMenuPosition({ x: e.pageX, y: e.pageY });
      setShowContextMenu(true);
    }
  };

  const hideContextMenu = () => {
    setContextMenuPosition({ x: 0, y: 0 });
    setShowContextMenu(false);
  };

  let content = null;

  if (participant) {
    let statusIndicatorColor = '#43B77A';
    if (!status || status === 'NOT_CONNECTED') {
      statusIndicatorColor = '#B71330';
    } else if (status === 'IN_SCREENING_QUEUE') {
      statusIndicatorColor = '#B7771E';
    }
    let containerClass = 'room-participant-list-item';
    if (subStatus && subStatus === 'TECH_SUPPORT_NEEDED' && roomIndex <= maxRooms) {
      containerClass += ' support';
    }
    if (!blockDrag) {
      containerClass += ' drag';
    }
    content = (
      <div
        className={containerClass}
        onContextMenu={onContextMenuHandler}
      >
        <span className='status-indicator' style={{ backgroundColor: statusIndicatorColor }} />
        <span className='participant-name'>
          {`${participant.firstName} ${participant.lastName || ''}`}
          {isHost ? ' (Host)' : null}
          {roomIndex > maxRooms && oldRoom ? ` (${oldRoom})` : null}
        </span>
        {showContextMenu === true ? (
          <ContextMenu position={contextMenuPosition} items={contextMenuItems} onClose={hideContextMenu} onItemSelected={contextMenuItemSelectionHandler} />
        ) : null}
      </div>
    );
    return blockDrag ? content : <Draggable dataTransfer={{ participant, roomId, subStatus }}>{content}</Draggable>;
  }
  return null;
};

const mapStateToProps = (state, ownProps) => {
  const { moving, participants, rooms, settingHost } = state.breakoutRooms;
  let participant;
  let roomId;
  let status;
  let subStatus;
  let roomIndex;
  let oldRoom;
  if (participants[ownProps.item]) {
    participant = participants[ownProps.item];
    roomIndex = participant.room;
    roomId = participant.roomId;
    oldRoom = rooms[participant.oldRoom] ? rooms[participant.oldRoom].index : null;
  }
  if (state.events.participants) {
    const participant = state.events.participants.find((p) => p.participant._id === ownProps.item);
    if (participant) {
      status = participant.status;
      subStatus = participant.subStatus;
    }
  }
  const isHost = participant && participant._id === ownProps.host;
  return {
    participant,
    moving: moving[ownProps.item],
    blockDrag: moving[ownProps.item] || settingHost[ownProps.item] || isHost || roomIndex > ownProps.maxRooms,
    enableContextMenu: ownProps.enableContextMenu && !moving[ownProps.item],
    isHost,
    roomId,
    status,
    subStatus,
    roomIndex,
    oldRoom,
  };
};

export default connect(mapStateToProps, null)(RoomParticipant);
