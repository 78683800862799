import { all, call, put, takeLeading, takeEvery, throttle } from 'redux-saga/effects';

import {
  actions,
  startSuccess,
  startFailure,
  stopSuccess,
  stopFailure,
  addParticipantSuccess,
  addParticipantFailure,
  removeParticipantSuccess,
  removeParticipantFailure,
  moveParticipantFailure,
  moveParticipantSuccess,
  updateBreakoutRoomConfigFailure,
  updateBreakoutRoomConfigSuccess,
  getBreakoutRoomConfigFailure,
  getBreakoutRoomConfigSuccess,
  addRoomsFailure,
  addRoomsSuccess,
  setHostFailure,
  setHostSuccess,
  autoAssignFailure,
  autoAssignSuccess,
  getBreakoutRoomConfigRequest,
  syncableActions,
} from '../redux/breakoutRoomsRedux';
import { get, post, put as update } from '../services/api';
import Logger from '../utils/logger';
import SocketClient from '../utils/socket-client';

const mlogger = new Logger('breakoutRoomsSaga');

export function* startRequest(action) {
  const logger = mlogger.deeper('startRequest', true);
  try {
    const { config } = action.payload;
    const startTime = new Date();
    logger.info('Calling api', { config, startTime });
    const response = yield call(post, '/breakoutRoom/start', {
      config,
      startTime,
    }, logger.trace);
    if (response.status === 200) {
      logger.info('Success', response.data);
      yield put(startSuccess({ startTime }));
    } else {
      logger.error('Failed', response.status, response.data);
      yield put(
        startFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    logger.error('Critical error', error);
    yield put(startFailure(error));
  }
}

export function* clearBreakoutRooms(action) {
  const logger = mlogger.deeper('clearBreakoutRooms', true);
  try {
    const { config } = action.payload;
    logger.info('Calling api', { config });
    const response = yield call(post, '/breakoutRoom/stop', { config }, logger.trace);
    if (response.status === 200) {
      logger.info('Success', response.data);
      yield put(stopSuccess(response.data));
    } else {
      logger.error('Failed', response.status, response.data);
      yield put(
        stopFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    logger.error('Critical  error', error);
    yield put(stopFailure(error));
  }
}

export function* addParticipant(action) {
  const logger = mlogger.deeper('addParticipant', true);
  const { roomId, participantId, configId, oldRoomId, removeSubStatus } = action.payload;
  try {
    logger.info('Calling api', action.payload);
    const response = yield call(post, `/breakoutRoom/${roomId}/addParticipant`, { uuid: participantId, configId, oldRoomId, removeSubStatus }, logger.trace);
    if (response.status === 200) {
      logger.info('Success', response.data);
      yield put(addParticipantSuccess({ roomId, participantId }));
    } else {
      logger.error('Failed', response.status, response.data);
      yield put(
        addParticipantFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    logger.error('Critical error', error);
    yield put(addParticipantFailure(participantId, error));
  }
}

export function* removeParticipant(action) {
  const logger = mlogger.deeper('removeParticipant', true);
  try {
    const { roomId, participantId, configId } = action.payload;
    logger.info('Calling api', action.payload);
    const response = yield call(post, `/breakoutRoom/${roomId}/removeParticipant`, { uuid: participantId, configId }, logger.trace);
    if (response.status === 200) {
      logger.info('Success', response.data);
      yield put(removeParticipantSuccess({ roomId, participantId }));
    } else {
      logger.error('Failed', response.status, response.data);
      yield put(
        removeParticipantFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    logger.error('Critical error', error);
    yield put(removeParticipantFailure(error));
  }
}

export function* moveParticipant(action) {
  const logger = mlogger.deeper('moveParticipant', true);
  const { from, to, participantId, configId, removeSubStatus, eventId } = action.payload;
  try {
    logger.info('Calling api', action.payload);
    const response = yield call(post, '/breakoutRoom/moveParticipant', {
      from,
      to,
      uuid: participantId,
      configId,
      removeSubStatus,
    }, logger.trace);
    if (response.status === 200) {
      logger.info('Getting updated room config');
      yield put(getBreakoutRoomConfigRequest(eventId, configId));

      logger.info('Success', response.data);
      yield put(moveParticipantSuccess({ from, to, participantId }));
    } else {
      logger.error('Failed', response.status, response.data);
      yield put(
        moveParticipantFailure(participantId, {
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(moveParticipantFailure(participantId, error));
  }
}

export function* getConfigRequest(action) {
  const logger = mlogger.deeper('getConfigRequest', true);
  try {
    const { event } = action.payload;
    logger.info('Getting config request');
    const response = yield call(get, `/breakoutRoom/config/${event}`, undefined, undefined, logger.trace);
    if (response.status === 200) {
      logger.info('Success', response.data);
      yield put(getBreakoutRoomConfigSuccess(response.data));
    } else {
      logger.error('Failure', response.status, response.data);
      yield put(
        getBreakoutRoomConfigFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    logger.error('Critical error', error);
    yield put(getBreakoutRoomConfigFailure(error));
  }
}

export function* updateConfigRequest(action) {
  const logger = mlogger.deeper('updateConfigRequest', true);
  try {
    const { event, config } = action.payload;
    logger.info('Calling api', { config });
    const response = yield call(update, `/breakoutRoom/config/${event}`, { config }, logger.trace);
    if (response.status === 200) {
      logger.info('Success', response.data);
      yield put(updateBreakoutRoomConfigSuccess(response.data));
    } else {
      logger.error('Failed', response.status, response.data);
      yield put(
        updateBreakoutRoomConfigFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    logger.error('Critical error', error);
    yield put(updateBreakoutRoomConfigFailure(error));
  }
}

export function* addRoomsRequest(action) {
  const logger = mlogger.deeper('addRoomsRequest', true);
  try {
    const { configId, numRooms, maxRooms } = action.payload;
    logger.info('Calling api', action.payload);
    const response = yield call(post, '/breakoutRoom/addRooms', { configId, numRooms, maxRooms }, logger.trace);
    if (response.status === 200) {
      logger.info('Success', response.data);
      yield put(addRoomsSuccess(response.data));
    } else {
      logger.error('Failed', response.status, response.data);
      yield put(
        addRoomsFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    logger.error('Critical error', error);
    yield put(addRoomsFailure(error));
  }
}

export function* setHostRequest(action) {
  const { participant, room } = action.payload;
  const logger = mlogger.deeper('setHostRequest', true);
  try {
    logger.info('Calling api', action.payload);
    const response = yield call(post, `/breakoutRoom/${room}/setHost`, { uuid: participant }, logger.trace);
    if (response.status === 200) {
      logger.info('Success', response.data);
      yield put(setHostSuccess({ participant, room }));
    } else {
      logger.error('Failed', response.status, response.data);
      yield put(
        setHostFailure(participant, {
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    logger.error('Critical error', error);
    yield put(setHostFailure(participant, error));
  }
}

export function* autoAssignRequest(action) {
  const logger = mlogger.deeper('autoAssignReques', true);
  try {
    const { config, rooms } = action.payload;
    logger.info('Calling api', action.payload);
    const response = yield call(post, '/breakoutRoom/autoAssign', { config, rooms }, logger.trace);
    if (response.status === 200) {
      logger.info('Success', response.data);
      yield put(autoAssignSuccess({ rooms }));
    } else {
      logger.error('Failed', response.status, response.data);
      yield put(
        autoAssignFailure({
          status: response.status,
          message: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(autoAssignFailure(error));
  }
}

export function appSync(action) {
  SocketClient.emitAdminAppSync(action);
}

export default function* () {
  yield all([
    takeLeading([actions.START_REQUEST], startRequest),
    takeLeading([actions.STOP_REQUEST], clearBreakoutRooms),
    takeEvery([actions.ADD_PARTICIPANT_REQUEST], addParticipant),
    takeEvery([actions.REMOVE_PARTICIPANT_REQUEST], removeParticipant),
    takeEvery([actions.MOVE_PARTICIPANT_REQUEST], moveParticipant),
    takeEvery([actions.GET_BREAKOUT_ROOM_CONFIG_REQUEST], getConfigRequest),
    takeLeading([actions.UPDATE_BREAKOUT_ROOM_CONFIG_REQUEST], updateConfigRequest),
    takeLeading([actions.ADD_ROOMS_REQUEST], addRoomsRequest),
    takeEvery([actions.SET_HOST_REQUEST], setHostRequest),
    takeLeading([actions.AUTO_ASSIGN_REQUEST], autoAssignRequest),
    throttle(200, syncableActions, appSync),
    takeLeading([actions.STOP_SUCCESS], appSync),
  ]);
}
