import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import Button from '../../../common/Button';
import RadioButtons from '../../../common/RadioButtons';
import TextInput from '../../../common/TextInput';
import DateTimeInput from '../../../common/DateTimeInput';
import FileInput from '../../../common/FileInput';
import Switch from '../../../common/Switch';
import Wysiwyg from './Wysiwyg';
import DropDownFormField from './DropDownFormField';
import Checkbox from '../../../common/Checkbox';
import TextArea from '../../../common/TextArea';
import StringUtil from '../../../utils/string-utils';
import ImageCache from '../../../utils/image-cache';
import { get, post } from '../../../services/api';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class EventForm extends PureComponent {
  constructor(props) {
    super(props);
    const isBroadcast = props.appMode === 'BROADCAST';
    this.state = {
      name: '',
      startTime: null,
      endTime: null,
      logoFile: null,
      logoFilename: '',
      allowEventSupportChatForPresenters: false,
      textChatForwardEmail: '',
      redirectUrl: '',
      maxOnWallAttendees: 40,
      maxOffWallAttendees: 1000,
      enableBreakoutRoomsRecording: false,
      appendAttendeeInformation: false,
      scheduledStartTime: new Date(),
      header: {},
      footer: {},
      htmlWidget: {},
      enableAutomatedOnboarding: isBroadcast ? false : true,
      enableOffWallAutomatedOnboarding: false,
      enableChat: isBroadcast ? false : true,
      enableReactions: true,
      enableQNAWidget: isBroadcast ? true : false,
      qnaForwardEmail: '',
      qnaWidgetAutoResponse: '',
      qnaPortalEventName: '',
      enableNotes: false,
      hideAttendeeDetails: false,
      latencyMode: 'LOW',
      erpAccountId: null,
      allowOffWallSelfElection: false,
      allowMobileDevices: false,
    };
  }

  static propTypes = {
    edit: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    edit: false,
  };

  componentDidMount() {
    const { model } = this.props;
    if (model) {
      this._setInitialState();
    } else {
      this.setState({ fetched: true });
    }
  }

  _setInitialState = async () => {
    try {
      const { model, fetchDataFromAPI } = this.props;

      let data = model;
      if (fetchDataFromAPI) {
        const response = await get(`/event/${model._id}`);
        data = response.data;
      }
      this.setState({
        eventId: data._id,
        name: data.name,
        startTime: data.startTime ? new Date(data.startTime) : null,
        endTime: data.endTime ? new Date(data.endTime) : null,
        allowEventSupportChatForPresenters: data.allowEventSupportChatForPresenters || false,
        textChatForwardEmail: data.textChatForwardEmail || '',
        redirectUrl: data.redirectUrl || '',
        maxOnWallAttendees: data.maxOnWallAttendees || 3,
        maxOffWallAttendees: data.maxOffWallAttendees || 1000,
        enableBreakoutRoomsRecording: data.enableBreakoutRoomsRecording || false,
        appendAttendeeInformation: data.appendAttendeeInformation || false,
        scheduledStartTime: data.scheduledStartTime ? new Date(data.scheduledStartTime) : null,
        header: data.header,
        footer: data.footer,
        htmlWidget: data.htmlWidget,
        fetched: true,
        logoFilename: data.logoFilename || '',
        enableAutomatedOnboarding: data.enableAutomatedOnboarding,
        enableOffWallAutomatedOnboarding: data.enableOffWallAutomatedOnboarding,
        enableChat: data.enableChat,
        enableReactions: data.enableReactions,
        enableQNAWidget: data.enableQNAWidget,
        qnaForwardEmail: data.qnaForwardEmail || '',
        qnaWidgetAutoResponse: data.qnaWidgetAutoResponse || '',
        qnaPortalEventName: data.qnaPortalEventName || '',
        enableNotes: data.enableNotes,
        hideAttendeeDetails: data.hideAttendeeDetails,
        latencyMode: data.broadcastStream && data.broadcastStream.latencyMode ? data.broadcastStream.latencyMode : 'LOW',
        erpAccountId: data.erpAccountId,
        allowOffWallSelfElection: data.allowOffWallSelfElection || false,
        allowMobileDevices: data.allowMobileDevices || false,
      });
    } catch (err) {
      console.error(err);
    }
  };

  _onChangeHandler = async (event) => {
    const { target } = event;
    if (target.name === 'logo') {
      try {
        const file = target.files[0];
        if (file.size > 512000) {
          throw new Error(`Cannot upload files larger than 512Kb`);
        }
        await new Promise((resolve, reject) => {
          let img = new Image();
          img.onload = () => {
            if (img.naturalWidth / img.naturalHeight !== 16 / 9) {
              reject(new Error('Image ratio needs to be 16:9'));
            } else if (img.naturalWidth > 1280 || img.naturalHeight > 720) {
              reject(new Error('The maximum allowed image resolution is 1280x720'));
            } else {
              resolve();
            }
          };
          img.src = URL.createObjectURL(file);
        });
        this.setState({ logoFile: file, logoFilename: file.name });
        if (this.props.model) {
          ImageCache.remove(this.props.model.logo);
        }
      } catch (error) {
        swal({
          title: 'Error',
          text: error.message,
        });
      }
    } else {
      this.setState({ [target.name]: target.value });
    }
  };

  _onDateChangeHandler = (fieldName, date) => {
    this.setState({ [fieldName]: date });
  };

  _onAttendenceCountChangeHandler = (name, selected) => {
    this.setState({ [name]: selected.value });
  };

  _onSubmitHandler = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { onSubmit } = this.props;
    const {
      name,
      startTime,
      endTime,
      logoFile,
      logoFilename,
      allowEventSupportChatForPresenters,
      textChatForwardEmail,
      redirectUrl,
      maxOnWallAttendees,
      maxOffWallAttendees,
      enableBreakoutRoomsRecording,
      appendAttendeeInformation,
      scheduledStartTime,
      enableAutomatedOnboarding,
      enableOffWallAutomatedOnboarding,
      enableChat,
      enableReactions,
      enableQNAWidget,
      qnaForwardEmail,
      qnaWidgetAutoResponse,
      qnaPortalEventName,
      enableNotes,
      hideAttendeeDetails,
      latencyMode,
      allowOffWallSelfElection,
      allowMobileDevices,
    } = this.state;

    const header = this._header.getData();
    const footer = this._footer.getData();
    const htmlWidget = this._htmlWidget.getData();

    if (redirectUrl && !StringUtil.validateUrl(redirectUrl)) {
      swal({
        title: 'Error',
        text: 'Invalid redirect URL',
      });
      return;
    }

    if (enableQNAWidget) {
      if (!qnaPortalEventName || qnaPortalEventName.length === 0) {
        swal({
          title: 'Error',
          text: 'The Q&A Portal Event Name field is required when enabling the Q&A Widget.',
        });
        return;
      }
      const exists = await this._verifyEventName();
      if (!exists) {
        swal({
          title: 'Error',
          text: 'Could not find event with this name in the Q&A Portal. Please check the name and try again.',
        });
        return;
      }
    }

    if (header.height > 200 || footer.height > 200) {
      swal({
        title: 'Error',
        text: 'Maximum Header or Footer height is 200px',
      });
    } else {
      onSubmit &&
        onSubmit({
          name,
          startTime,
          endTime,
          logoFile,
          logoFilename,
          allowEventSupportChatForPresenters,
          textChatForwardEmail,
          redirectUrl,
          maxOnWallAttendees,
          maxOffWallAttendees,
          enableBreakoutRoomsRecording,
          appendAttendeeInformation,
          scheduledStartTime,
          header,
          footer,
          htmlWidget,
          enableAutomatedOnboarding,
          enableOffWallAutomatedOnboarding,
          enableChat,
          enableReactions,
          enableQNAWidget,
          qnaForwardEmail,
          qnaWidgetAutoResponse,
          qnaPortalEventName,
          enableNotes,
          hideAttendeeDetails,
          latencyMode,
          allowOffWallSelfElection,
          allowMobileDevices,
        });
    }
  };

  _testUrl = () => {
    const { redirectUrl, appendAttendeeInformation } = this.state;
    this.props.onTestUrl && this.props.onTestUrl({ redirectUrl, appendAttendeeInformation });
  };

  _verifyEventName = async () => {
    try {
      const { qnaPortalEventName } = this.state;
      const response = await post(`/qna/verifyEventName`, { name: qnaPortalEventName });
      return response.data.exists;
    } catch (err) {
      console.error(err);
    }
  };

  _toggleQNAWidget = () => {
    const { enableQNAWidget } = this.state;
    this.setState({ enableQNAWidget: !enableQNAWidget });
  };

  render() {
    const { testRedirectUrl, customHeader, customHeaderStyle, formContainerStyle, appMode } = this.props;
    const {
      eventId,
      name,
      startTime,
      endTime,
      textChatForwardEmail,
      allowEventSupportChatForPresenters,
      redirectUrl,
      maxOnWallAttendees,
      maxOffWallAttendees,
      enableBreakoutRoomsRecording,
      appendAttendeeInformation,
      scheduledStartTime,
      header,
      footer,
      htmlWidget,
      logoFilename,
      fetched,
      enableAutomatedOnboarding,
      enableOffWallAutomatedOnboarding,
      enableChat,
      enableReactions,
      enableQNAWidget,
      qnaForwardEmail,
      qnaWidgetAutoResponse,
      enableNotes,
      qnaPortalEventName,
      hideAttendeeDetails,
      latencyMode,
      erpAccountId,
      allowOffWallSelfElection,
      allowMobileDevices,
    } = this.state;

    if (!fetched) return null;

    return (
      <div className='modal-form'>
        {customHeader && (
          <div className='modal-form-header' style={customHeaderStyle}>
            {customHeader}
          </div>
        )}
        <div className='modal-form-body' style={formContainerStyle}>
          <form autoComplete='off' noValidate onSubmit={this._onSubmitHandler}>
            <div className='modal-form-content' style={{ height: 'calc(100% - 80px)' }}>
              <section className='form-section' style={{ marginBottom: 40 }}>
                <h4>General</h4>
                <TextInput name='name' label='Name' onChange={this._onChangeHandler} value={name} disabled={!!erpAccountId} />
                <DateTimeInput
                  name='scheduledStartTime'
                  label='Scheduled Date Time'
                  onChange={(date) => this._onDateChangeHandler('scheduledStartTime', date)}
                  value={scheduledStartTime}
                />
                <DateTimeInput name='startTime' label='Actual Start Time' onChange={(date) => this._onDateChangeHandler('startTime', date)} value={startTime} />
                <DateTimeInput name='endTime' label='Actual End Time' onChange={(date) => this._onDateChangeHandler('endTime', date)} value={endTime} />

                <TextInput name='redirectUrl' label='Redirect Url' onChange={this._onChangeHandler} value={redirectUrl} />
                <div className='form-group' style={{ marginTop: 15, paddingLeft: 0 }}>
                  <div className='form-label' />
                  <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Checkbox
                      label='Append Attendee Information'
                      value={appendAttendeeInformation}
                      onChange={(value) => this.setState({ appendAttendeeInformation: value })}
                    />
                    {testRedirectUrl && <Button type='link' text='Test URL' containerStyle={{ padding: 0, fontSize: 13 }} onClick={this._testUrl} />}
                  </div>
                </div>
                {appMode === 'PANDO' && (
                  <>
                    <FileInput name='logo' label='Event Logo' filename={logoFilename} showPreview={true} onChange={this._onChangeHandler} />
                    <div className='form-group'>
                      <div className='form-label'>Max On Wall Attendees</div>
                      <div
                        className='form-control'
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}
                      >
                        <DropDownFormField
                          name='maxOnWallAttendees'
                          label='Max On Wall Attendees'
                          list={[
                            { value: 3, label: 3 },
                            { value: 15, label: 15 },
                            { value: 20, label: 20 },
                            { value: 30, label: 30 },
                            { value: 40, label: 40 },
                            { value: 80, label: 80 },
                            { value: 120, label: 120 },
                            { value: 160, label: 160 },
                          ]}
                          value={maxOnWallAttendees}
                          onChange={this._onAttendenceCountChangeHandler}
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='form-label'>Max Off Wall Attendees</div>
                      <div
                        className='form-control'
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}
                      >
                        <DropDownFormField
                          name='maxOffWallAttendees'
                          label='Max Off Wall Attendees'
                          list={[
                            { value: 500, label: 500 },
                            { value: 1000, label: 1000 },
                            { value: 2500, label: 2500 },
                            { value: 5000, label: 5000 },
                          ]}
                          value={maxOffWallAttendees}
                          onChange={this._onAttendenceCountChangeHandler}
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='form-label'>Enable Reactions</div>
                      <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Switch
                          label=''
                          onChange={() => this.setState({ enableReactions: !enableReactions })}
                          checked={enableReactions}
                          height={16}
                          width={34}
                          containerStyle={{ vAlign: 'center', marginBottom: 0 }}
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='form-label'>Enable Note Taking</div>
                      <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Switch
                          label=''
                          onChange={() => this.setState({ enableNotes: !enableNotes })}
                          checked={enableNotes}
                          height={16}
                          width={34}
                          containerStyle={{ vAlign: 'center', marginBottom: 0 }}
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='form-label'>Record Breakout Rooms</div>
                      <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Switch
                          label=''
                          onChange={() => this.setState({ enableBreakoutRoomsRecording: !enableBreakoutRoomsRecording })}
                          checked={enableBreakoutRoomsRecording}
                          height={16}
                          width={34}
                          containerStyle={{ vAlign: 'center', marginBottom: 0 }}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className='form-group'>
                  <div className='form-label'>Hide Attendee Details</div>
                  <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Switch
                      label=''
                      onChange={() => this.setState({ hideAttendeeDetails: !hideAttendeeDetails })}
                      checked={hideAttendeeDetails}
                      height={16}
                      width={34}
                      containerStyle={{ vAlign: 'center', marginBottom: 0 }}
                    />
                  </div>
                </div>
                {appMode === 'PANDO' && (
                  <div className='form-group'>
                    <div className='form-label'>Allow Mobile Devices</div>
                    <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Switch
                        label=''
                        onChange={() => this.setState({ allowMobileDevices: !allowMobileDevices })}
                        checked={allowMobileDevices}
                        height={16}
                        width={34}
                        containerStyle={{ vAlign: 'center', marginBottom: 0 }}
                      />
                    </div>
                  </div>
                )}
                {appMode === 'BROADCAST' && (
                  <div className='form-group'>
                    <div className='form-label'>Latency</div>
                    <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                      <RadioButtons
                        label='Latency'
                        onChange={(type) => {
                          this.setState({ latencyMode: type });
                        }}
                        options={[
                          {
                            label: 'Low',
                            value: 'LOW',
                          },
                          {
                            label: 'Normal',
                            value: 'NORMAL',
                          },
                        ]}
                        value={latencyMode}
                        inline
                      />
                    </div>
                  </div>
                )}
              </section>
              {appMode === 'PANDO' && (
                <>
                  <section className='form-section'>
                    <h4>Automated Onboarding</h4>
                    <div className='form-group'>
                      <div className='form-label'>Enabled</div>
                      <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Switch
                          label=''
                          onChange={() =>
                            this.setState({ enableAutomatedOnboarding: !enableAutomatedOnboarding }, () => {
                              if (!this.state.enableAutomatedOnboarding) {
                                this.setState({ enableOffWallAutomatedOnboarding: false, allowOffWallSelfElection: false });
                              }
                            })
                          }
                          checked={enableAutomatedOnboarding}
                          height={16}
                          width={34}
                          containerStyle={{ vAlign: 'center', marginBottom: 0 }}
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='form-label'>Required for Off-Wall</div>
                      <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Switch
                          label=''
                          onChange={() => this.setState({ enableOffWallAutomatedOnboarding: !enableOffWallAutomatedOnboarding })}
                          checked={enableOffWallAutomatedOnboarding}
                          height={16}
                          width={34}
                          containerStyle={{ vAlign: 'center', marginBottom: 0 }}
                          disabled={!enableAutomatedOnboarding}
                        />
                      </div>
                    </div>
                    <div className='form-group'>
                      <div className='form-label'>Allow Off-Wall Self Election</div>
                      <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Switch
                          label=''
                          onChange={() => this.setState({ allowOffWallSelfElection: !allowOffWallSelfElection })}
                          checked={allowOffWallSelfElection}
                          height={16}
                          width={34}
                          containerStyle={{ vAlign: 'center', marginBottom: 0 }}
                          disabled={!enableAutomatedOnboarding}
                        />
                      </div>
                    </div>
                  </section>
                </>
              )}
              <Wysiwyg
                ref={(c) => (this._header = c)}
                title='HEADER'
                displayEnabled
                enabled={header.enabled}
                height={header.height}
                bgColor={header.bgColor}
                markup={header.html}
                isHtmlLocked
                displayShowPandoLogo={false}
                allowSelectUnits={false}
              />
              <Wysiwyg
                ref={(c) => (this._footer = c)}
                title='FOOTER'
                displayEnabled={false}
                enabled
                height={footer.height}
                bgColor={footer.bgColor !== undefined ? footer.bgColor : '#202a36'}
                markup={footer.html}
                isHtmlLocked={footer.isHtmlLocked}
                displayShowPandoLogo={false}
                showPandoLogo={true}
                allowSelectUnits={false}
              />
              <Wysiwyg
                ref={(c) => (this._htmlWidget = c)}
                title='HTML WIDGET'
                displayEnabled
                enabled={htmlWidget && htmlWidget.enabled}
                displayShowPandoLogo={false}
                displayShowHtml={false}
                displayWidth={true}
                displayPinTo={true}
                displaySpacing={true}
                height={htmlWidget && htmlWidget.height}
                heightUnits={htmlWidget && htmlWidget.heightUnits}
                width={htmlWidget && htmlWidget.width}
                widthUnits={htmlWidget && htmlWidget.widthUnits}
                bgColor={htmlWidget && htmlWidget.bgColor !== undefined ? htmlWidget.bgColor : '#202a36'}
                markup={htmlWidget && htmlWidget.html}
                pinTo={htmlWidget && htmlWidget.pinTo}
                spacing={htmlWidget && htmlWidget.spacing}
                isHtmlLocked
                allowSelectUnits
              />
              <section className='form-section'>
                <h4>Chat</h4>
                <div className='form-group'>
                  <div className='form-label'>Enabled</div>
                  <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Switch
                      label=''
                      onChange={() => this.setState({ enableChat: !enableChat })}
                      checked={enableChat}
                      height={16}
                      width={34}
                      containerStyle={{ vAlign: 'center', marginBottom: 0 }}
                    />
                  </div>
                </div>
                {enableChat && (
                  <>
                    <TextInput name='textChatForwardEmail' label='Forward Messages' onChange={this._onChangeHandler} value={textChatForwardEmail} />
                    {appMode === 'PANDO' && (
                      <>
                        <div className='form-group' style={{ marginTop: 15 }}>
                          <div className='form-label'></div>
                          <div className='form-control'>
                            <Checkbox
                              label='Give Wall Presenters advanced chat capabilities'
                              value={allowEventSupportChatForPresenters}
                              onChange={(value) => this.setState({ allowEventSupportChatForPresenters: value })}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </section>
              <section className='form-section'>
                <h4>Q&A Widget</h4>
                <div className='form-group'>
                  <div className='form-label'>Enabled</div>
                  <div className='form-control' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Switch
                      label=''
                      onChange={this._toggleQNAWidget}
                      checked={enableQNAWidget}
                      height={16}
                      width={34}
                      containerStyle={{ vAlign: 'center', marginBottom: 0 }}
                    />
                  </div>
                </div>
                {enableQNAWidget && (
                  <>
                    <TextInput name='qnaPortalEventName' label='Q&A Portal Event Name' onChange={this._onChangeHandler} value={qnaPortalEventName} />
                    <TextInput name='qnaForwardEmail' label='Forward Questions' onChange={this._onChangeHandler} value={qnaForwardEmail} />
                    <TextArea name='qnaWidgetAutoResponse' label='Auto-Response' onChange={this._onChangeHandler} value={qnaWidgetAutoResponse} />
                  </>
                )}
              </section>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  appMode: state.ui.appMode,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(EventForm);
