import React from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert';

import { setHostRequest, moveParticipantRequest, removeParticipantRequest } from '../../redux/breakoutRoomsRedux';
import List from '../../common/List';
import SocketClient from '../../utils/socket-client';

import RoomParticipant from './RoomParticipant';

const Room = (props) => {
  const {
    capacity,
    index,
    onAddParticipant,
    onMoveParticipant,
    participants,
    participantCount,
    roomId,
    host,
    isSettingHost,
    participantsWithSubStatus,
    config,
    rooms,
    moveParticipant,
    participantsData,
    startTime,
    eventParticipants,
    removeParticipant,
    maxRooms,
    settingHost,
    moving,
  } = props;

  const _getContextMenuItemSelectionHandler = (item) => async (option) => {
    const { setHost, roomId, activeEvent } = props;
    let newRoomId;

    if (!option.disabled) {
      switch (option.value) {
        case 1:
          setHost(item, roomId);
          break;
        case 2:
          setHost(undefined, roomId);
          break;
        case 3:
          for (let id in rooms) {
            if (rooms[id].index === maxRooms + 1) {
              newRoomId = rooms[id]._id;
            }
          }
          moveParticipant(roomId, newRoomId, item, config._id, false, activeEvent._id);
          break;
        case 4:
          for (let id in rooms) {
            if (rooms[id].index === maxRooms + 2) {
              newRoomId = rooms[id]._id;
            }
          }
          moveParticipant(roomId, newRoomId, item, config._id, false, activeEvent._id);
          break;
        case 5:
          if (participantsData[item].oldRoom) {
            moveParticipant(roomId, participantsData[item].oldRoom, item, config._id, true, activeEvent._id);
          }
          break;
        case 6:
          removeParticipant(roomId, item, config._id);
          break;
        default:
          break;
      }
    }
  };

  const _getContextMenuItems = (item) => {
    const _contextMenuItems = [];

    if (eventParticipants) {
      const participant = eventParticipants.find((e) => e.participant._id === item);

      if (host && host !== null) {
        if (item === host) {
          _contextMenuItems.push({
            value: 2,
            label: 'Remove host',
            danger: true,
          });
        }
      } else {
        if (participant && participant.status !== 'IN_SCREENING_QUEUE' && participant.status !== 'NOT_CONNECTED') {
          _contextMenuItems.push({
            value: 1,
            label: 'Set as host',
          });
        }
      }

      if (item !== host && index <= maxRooms && startTime) {
        _contextMenuItems.push(
          {
            value: 3,
            label: 'Send to TS 1',
          },
          {
            value: 4,
            label: 'Send to TS 2',
          },
        );
      }
      if (item !== host && index > maxRooms) {
        if (!participantsData[item].oldRoom) {
          _contextMenuItems.push({
            value: 6,
            label: 'Send back to queue',
          });
        } else {
          _contextMenuItems.push({
            value: 5,
            label: 'Send back to breakout room',
          });
        }
      }
    }

    return _contextMenuItems;
  };

  const _onDropHandler = async (event) => {
    const { dataTransfer } = event;
    let dt;
    try {
      dt = JSON.parse(dataTransfer.getData('text'));
    } catch (error) {
      console.error('invalid data', error);
      return;
    }
    const isNewParticipant = participants.indexOf(dt.participant._id) === -1;
    const roomIsNotFull = participants.length < capacity;

    if (!roomIsNotFull) {
      await swal({
        title: 'Error',
        text: 'Room is full',
      });
    }

    if (moving[dt.participant._id]) {
      console.error(`Participant ${dt.participant._id} is moving already`);
      return;
    }

    if (isNewParticipant && roomIsNotFull && index <= maxRooms) {
      const oldRoomId = dt.roomId || (props.participantsData[dt.participant._id] ? props.participantsData[dt.participant._id].roomId : undefined);
      const oldRoom = rooms[oldRoomId];
      if (oldRoom) {
        if (settingHost[dt.participant._id]) {
          await swal({
            title: 'Error',
            text: `Participant's host status is changing at room ${oldRoom.index}`,
          });
          return;
        }
        if (oldRoom.host === dt.participant._id) {
          await swal({
            title: 'Error',
            text: `Participant ${dt.participant._id} is the host of room ${oldRoom.index}`,
          });
          return;
        }
      }

      if (dt.roomId) {
        onMoveParticipant && onMoveParticipant({ participantId: dt.participant._id, oldRoomId, newRoomId: roomId, removeSubStatus: true });
      } else {
        onAddParticipant && onAddParticipant({ participantId: dt.participant._id, roomId, oldRoomId, removeSubStatus: true });
      }
    }
  };

  const _onDragOverHandler = (event) => event.preventDefault();

  const roomTitle = index <= maxRooms ? <span className={host ? 'green' : 'yellow'}>{`Room ${index}`}</span> : <span className='red'>{`Tech Support ${index - maxRooms}`}</span>;
  const badQuantity = participantCount === 0 || participantCount > capacity;

  return (
    <div
      className={participantsWithSubStatus && participantsWithSubStatus.length > 0 && index <= maxRooms ? 'room pulse' : 'room'}
      style={{ gridArea: `room${index}` }}
    >
      <div className='room-header'>
        {roomTitle}
        <span className={badQuantity ? 'red' : 'green'}>{`${participantCount} / ${capacity}`}</span>
      </div>
      <div className='room-participants' onDrop={_onDropHandler} onDragOver={_onDragOverHandler}>
        <List
          data={participants}
          renderItem={(item) => (
            <RoomParticipant
              item={item}
              host={host}
              contextMenuItems={_getContextMenuItems(item)}
              enableContextMenu={!isSettingHost}
              contextMenuItemSelectionHandler={_getContextMenuItemSelectionHandler(item)}
              maxRooms={maxRooms}
            />
          )}
          keyExtractor={(item) => `${item}`}
          containerStyle={{ position: 'relative' }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { moving, rooms, settingHost } = state.breakoutRooms;
  let participants = [];
  let host;
  let participantsWithSubStatus;
  let isSettingHost = false;
  if (rooms[ownProps.roomId]) {
    participants = rooms[ownProps.roomId].participants;
    host = rooms[ownProps.roomId].host;
    if (state.events.participants) {
      participantsWithSubStatus = state.events.participants.filter((p) => {
        return p.participant && participants.includes(p.participant._id) && p.subStatus && p.subStatus === 'TECH_SUPPORT_NEEDED';
      });
    }
    isSettingHost = Boolean(participants.find(p => settingHost[p]));
  }
  return {
    config: state.breakoutRooms.config,
    participantCount: participants.length,
    participants,
    host,
    started: state.breakoutRooms.started,
    eventParticipants: state.events.participants,
    participantsWithSubStatus,
    isSettingHost,
    rooms,
    participantsData: state.breakoutRooms.participants,
    activeEvent: state.events.active,
    settingHost,
    moving,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setHost: (host, room) => dispatch(setHostRequest(host, room)),
  moveParticipant: (from, to, participantId, configId, removeSubStatus, eventId) =>
    dispatch(moveParticipantRequest(from, to, participantId, configId, removeSubStatus, eventId)),
  removeParticipant: (roomId, participantId, configId) => dispatch(removeParticipantRequest(roomId, participantId, configId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Room);
