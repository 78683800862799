import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import isInsideContainer from '../utils/is-inside-container';
import ContextMenuItem from './ContextMenuItem';
import '../css/ContextMenu.css';

class ContextMenu extends PureComponent {
  _ctxMenuContainer = null;

  componentDidMount() {
    const { position, items } = this.props;
    const { x, y } = position;
    this._ctxMenuContainer = document.createElement('div');
    this._ctxMenuContainer.classList.add('context-menu-container');

    if (x + 200 > window.innerWidth) {
      this._ctxMenuContainer.style.left = `${x - (x + 200 - window.innerWidth)}px`;
    } else {
      this._ctxMenuContainer.style.left = `${x}px`;
    }

    const menuItemHeightPx = 42;
    const containerHeight = items.length * menuItemHeightPx;

    if (y + containerHeight > (window.innerHeight - 20)) {
      this._ctxMenuContainer.style.top = `${y - (y + containerHeight - (window.innerHeight - 20))}px`;
    } else {
      this._ctxMenuContainer.style.top = `${y}px`;
    }

    document.body.appendChild(this._ctxMenuContainer);
    document.body.addEventListener('mousedown', this._hideContextMenu);

    const menuItems = items.map((item) => (
      <ContextMenuItem key={`menu-item-${item.value}`} item={item} onSelectItem={this._selectItem} menuPosition={position} />
    ));
    ReactDOM.render(<div className='context-menu-items-container'>{menuItems}</div>, this._ctxMenuContainer);
  }

  _selectItem = (e, item, subItem) => {
    e.stopPropagation();
    const { onItemSelected } = this.props;
    this._removeMenu();
    onItemSelected && onItemSelected(item, subItem);
  };

  _removeMenu = () => {
    const { onClose } = this.props;
    document.body.removeChild(this._ctxMenuContainer);
    document.body.removeEventListener('mousedown', this._hideContextMenu);
    onClose && onClose();
  };

  _hideContextMenu = (e) => {
    if (isInsideContainer(e.target, this._ctxMenuContainer)) return;
    this._removeMenu();
  };

  render() {
    return null;
  }
}

export default ContextMenu;
